import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { clearAll } from 'common';
import type RouterService from '@ember/routing/router-service';
import type { BenchSession } from 'common';
import type QueryParams from 'ember-performance/services/query-params';
// https://stackoverflow.com/a/12646864
function shuffleArray(array1) {
    for(let i1 = array1.length - 1; i1 > 0; i1--){
        const j1 = Math.floor(Math.random() * (i1 + 1));
        [array1[i1], array1[j1]] = [
            array1[j1],
            array1[i1]
        ];
    }
}
export class Runner extends Component {
    @service
    router: RouterService;
    @service
    queryParams: QueryParams;
    @service
    benchSession: BenchSession;
    @tracked
    testUrl = '';
    @tracked
    isRunning = false;
    @tracked
    totalTests = 0;
    @tracked
    remainingTests = 0;
    @tracked
    status = '';
    waiter?: (value?: unknown) => void;
    currentIframeWindow?: Window | null;
    emitReady = (event1: Event)=>{
        assert(`event.target cannot be null`, event1.target);
        assert(`event.target must be an iframe element`, event1.target instanceof HTMLIFrameElement);
        let win1 = event1.target.contentWindow;
        this.currentIframeWindow = win1;
        assert(`Cannot resume when there is no waiter`, this.waiter);
        this.waiter();
    };
    /**
   * This is gross, I'm sorry
   */ start = async ()=>{
        try {
            await Promise.resolve();
            this.isRunning = true;
            if (this.queryParams.clear.value) {
                this.queryParams.clear.toggle();
                clearAll();
            }
            let isRandom1 = this.queryParams.randomize.value;
            let remainingVersions1 = [
                ...this.queryParams.emberVersions.value
            ];
            let remainingTests1 = [
                ...this.queryParams.benchmarks.value
            ];
            this.totalTests = remainingVersions1.length * remainingTests1.length;
            this.remainingTests = this.totalTests;
            if (isRandom1) {
                shuffleArray(remainingVersions1);
            }
            while(remainingVersions1.length){
                let current1 = remainingVersions1.pop();
                let testsForVersion1 = [
                    ...remainingTests1
                ];
                if (isRandom1) {
                    shuffleArray(testsForVersion1);
                }
                while(testsForVersion1.length){
                    let bench1 = testsForVersion1.pop();
                    let waitForIFrameLoad1 = new Promise((resolve1)=>(this.waiter = resolve1));
                    let { protocol: protocol1, host: host1 } = window.location;
                    let subPath1 = `${current1.replace(`.`, `-`)}`;
                    let timePerTest1 = this.queryParams.timePerTest.value;
                    this.testUrl = `${protocol1}//${host1}/${subPath1}/index.html?run=${bench1}&timePerTest=${timePerTest1}`;
                    await waitForIFrameLoad1;
                    assert(`Could not determine iframe for test`, this.currentIframeWindow);
                    let finish1: (v?: unknown) => void;
                    let finishPromise1 = new Promise((resolve1)=>(finish1 = resolve1));
                    // eslint-disable-next-line
                    const waitForFinish1 = async (event1: MessageEvent)=>{
                        if (event1.data === 'finish') {
                            window.removeEventListener('message', waitForFinish1);
                            // Removes the IFrame
                            this.testUrl = '';
                            this.remainingTests--;
                            // This is an arbitrary timeout of 1s to try to give the Garbage Collector some time to catch up.
                            // The goal is to reduce the variance in the tests.
                            this.status = 'Letting GC catch up...';
                            await new Promise((resolve1)=>setTimeout(resolve1, 1000));
                            this.status = '';
                            finish1();
                        }
                    };
                    window.addEventListener('message', waitForFinish1);
                    this.currentIframeWindow.postMessage(JSON.stringify([
                        'run',
                        bench1
                    ]));
                    await finishPromise1;
                }
            }
        } finally{
            this.isRunning = false;
            this.router.transitionTo('report');
        }
    };
    get canStart() {
        return !this.isRunning;
    }
    get cannotStart() {
        return !this.canStart;
    }
    static{
        template(`
    <div class="footer">
      <button
        type="button"
        class="btn btn-primary"
        {{on "click" this.start}}
        disabled={{this.cannotStart}}
      >Run Tests</button>

      {{#if this.isRunning}}
        Running
        {{this.totalTests}}
        Tests.
        {{this.remainingTests}}
        Remaining...
        {{this.status}}
      {{/if}}
    </div>

    {{#if this.testUrl}}
      <iframe
        title="Current Test"
        src={{this.testUrl}}
        style="width: 300px; min-height: 400px; position: fixed; right: 0.5rem; bottom: 0.5rem; border: 1px solid; border-radius: 0.5rem; background: white;"
        {{on "load" this.emitReady}}
      ></iframe>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
