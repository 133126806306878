import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { looseSemverCompare } from '../utils';
import AreaChart from './area-chart';
import { formatNumber, getAltName } from './utils';
function cleanedVersion(version1) {
    /**
   * version is potentially a path to a tgz
   */ if (version1.includes('/')) {
        return version1.split('/').at(-1).replace('ember-source-', '');
    }
    /**
   * version is a local tgz, probably
   */ if (version1.startsWith('file:')) {
        return version1.split('file:').at(-1).replace('ember-source-', '');
    }
    return version1;
}
export default class BenchmarkReport extends Component {
    get showGraph() {
        return this.args.report.length > 1;
    }
    chartOptions = {
        title: 'Time (ms) (lower is better)',
        hAxis: {
            title: 'Ember Version'
        },
        intervals: {
            style: 'area'
        },
        legend: 'none'
    };
    get groupedTests() {
        const tests1 = {};
        let sorted1 = this.args.report.toSorted((a1, b1)=>looseSemverCompare(a1.version, b1.version));
        // eslint-disable-next-line no-console
        console.log('sorted data', sorted1);
        for (let data1 of sorted1){
            let { name: name1, results: results1, version: version1, altName: altName1 } = data1;
            let result1 = results1;
            const test1 = tests1[name1] || {
                name: name1,
                data: [],
                chartData: [
                    [
                        'Ember Version',
                        'Time in ms (lower is better)',
                        {
                            role: 'interval'
                        },
                        {
                            role: 'interval'
                        }
                    ]
                ]
            };
            test1.data.push({
                emberVersion: cleanedVersion(version1),
                altName: altName1,
                result: result1
            });
            test1.chartData.push({
                emberVersion: cleanedVersion(version1),
                altName: altName1,
                mean: result1.mean,
                margin_error_lower: Math.max(result1.mean - (result1.mean * result1.rme) / 100, 0),
                margin_error_upper: result1.mean + (result1.mean * result1.rme) / 100
            });
            tests1[name1] = test1;
        }
        return tests1;
    }
    static{
        template(`
    <h4>Results:</h4>

    {{#each-in this.groupedTests as |name test|}}
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">{{test.name}}</h3>
        </div>
        <div class="panel-body">
          {{#if this.showGraph}}
            <AreaChart @data={{test.chartData}} @options={{this.chartOptions}} />
          {{/if}}
          <table class="table table-striped table-hover table-condensed table-responsive">
            <thead>
              <tr>
                <th>Name</th>
                <th class="numeric">Speed</th>
                <th class="numeric">Error</th>
                <th class="numeric">Samples</th>
                <th class="numeric">Mean</th>
              </tr>
            </thead>
            <tbody>
              {{#each test.data as |item|}}
                <tr>
                  <td>
                    <strong>{{test.name}}</strong>
                    <span class="label label-primary">{{item.emberVersion}}
                      {{getAltName item.altName}}</span>
                  </td>
                  <td class="numeric">{{formatNumber
                      item.result.hz
                      minimumFractionDigits=2
                      maximumFractionDigits=2
                    }}
                    / sec
                  </td>
                  <td class="numeric">&#x2213;
                    {{formatNumber
                      item.result.rme
                      minimumFractionDigits=2
                      maximumFractionDigits=2
                    }}%
                  </td>
                  <td class="numeric">{{item.result.numSamples}}</td>
                  <td class="numeric">{{formatNumber item.result.mean mode="auto"}}
                    ms
                  </td>
                </tr>
              {{/each}}
            </tbody>
          </table>
        </div>
      </div>
    {{/each-in}}

    <hr />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
