import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Chart from 'chart.js/auto';
import { modifier } from 'ember-modifier';
import { formatNumber, getAltName } from './utils';
export default class AreaChart extends Component {
    limit = 8;
    total = 0;
    options = null;
    renderChart = modifier((element1)=>{
        this._renderChart(element1?.querySelector('.chart-canvas'));
        return ()=>{
            this._chart?.destroy();
        };
    });
    _chart = null;
    _renderChart(canvasElement1) {
        if (!canvasElement1) {
            return;
        }
        const context1 = canvasElement1.getContext('2d');
        const chartData1 = this.args.data;
        const [axis1, ...datasets1] = chartData1;
        const labels1 = datasets1.map(({ emberVersion: emberVersion1, altName: altName1 })=>{
            return emberVersion1 + getAltName(altName1);
        });
        const data1 = {
            labels: labels1,
            datasets: [
                {
                    label: 'Mean',
                    data: datasets1.map((v1)=>{
                        return v1.mean;
                    }),
                    backgroundColor: 'transparent',
                    borderColor: 'steelblue',
                    pointRadius: 2,
                    pointBackgroundColor: 'steelblue',
                    pointBorderColor: 'steelblue',
                    borderWidth: 5
                },
                {
                    data: datasets1.map((v1)=>v1.margin_error_lower),
                    backgroundColor: 'rgb(0, 0, 0, 0.1)',
                    borderColor: 'transparent',
                    fill: 0,
                    pointRadius: 0,
                    borderWidth: 0
                },
                {
                    data: datasets1.map((v1)=>v1.margin_error_upper),
                    backgroundColor: 'rgb(0, 0, 0, 0.1)',
                    borderColor: 'transparent',
                    fill: 0,
                    pointRadius: 0,
                    borderWidth: 0
                }
            ]
        };
        if (!canvasElement1) {
            return;
        }
        this._chart?.destroy();
        this._chart = new Chart(context1, this.buildChartConfig(data1, {
            axis: axis1
        }));
    }
    buildChartConfig(data1, { axis: axis1 } = {}) {
        return {
            type: 'line',
            data: data1,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                resizeDelay: 50,
                scales: {
                    x: {
                        type: 'category',
                        title: {
                            display: axis1?.[0],
                            text: axis1?.[0]
                        },
                        grid: {
                            drawOnChartArea: false,
                            drawTicks: true
                        }
                    },
                    y: {
                        type: 'linear',
                        grace: 0,
                        title: {
                            display: false
                        },
                        ticks: {
                            callback: function(value1) {
                                return formatNumber(value1);
                            }
                        }
                    }
                },
                plugins: {
                    title: {
                        display: axis1?.[1],
                        align: 'start',
                        text: axis1?.[1]
                    },
                    legend: {
                        display: false
                    },
                    filler: {
                        propagate: false
                    },
                    tooltip: {
                        displayColors: false,
                        filter: (context1)=>{
                            return context1.datasetIndex === 0;
                        },
                        callbacks: {
                            label: (context1)=>`${formatNumber(context1.parsed.y)}ms`
                        }
                    }
                },
                pointBackgroundColor: '#fff',
                radius: 10,
                interaction: {
                    mode: 'index',
                    intersect: false
                }
            }
        };
    }
    static{
        template(`
    <div class="chart-canvas-container" {{this.renderChart}}>
      <canvas class="chart-canvas"></canvas>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
